// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_content__15oFw {\n  width: calc(100% - 92px);\n  height: calc(100vh - 50px);\n  margin-left: 92px;\n}\n@media only screen and (max-width: 700px) {\n  .styles_content__15oFw {\n    width: 100%;\n    height: calc(100vh - 92px);\n    margin-left: 0;\n    margin-bottom: 92px;\n  }\n}", "",{"version":3,"sources":["webpack://src/components/App/styles.module.scss","webpack://src/spacings.module.scss"],"names":[],"mappings":"AAEA;EACE,wBAAA;EACA,0BAAA;EACA,iBCMc;ADPhB;AAGE;EALF;IAMI,WAAA;IACA,0BAAA;IACA,cAAA;IACA,mBAAA;EAAF;AACF","sourcesContent":["@import \"../../theme.module.scss\";\r\n\r\n.content {\r\n  width: calc(100% - $width-nav-bar);\r\n  height: calc(100vh - $height-footer);\r\n  margin-left: $width-nav-bar;\r\n\r\n  @media only screen and (max-width: $breakpoint-s) {\r\n    width: 100%;\r\n    height: calc(100vh - $width-nav-bar);\r\n    margin-left: 0;\r\n    margin-bottom: $width-nav-bar;\r\n  }\r\n}\r\n","$spacing-xxxs: 4px;\r\n$spacing-xxs: 8px;\r\n$spacing-xs: 12px;\r\n$spacing-s: 16px;\r\n$spacing-m: 24px;\r\n$spacing-l: 32px;\r\n$spacing-xl: 40px;\r\n\r\n$breakpoint-xs: 500px;\r\n$breakpoint-s: 700px;\r\n\r\n$width-nav-bar: 92px;\r\n$height-footer: 50px;\r\n\r\n@mixin padding-s-m {\r\n  padding: $spacing-s $spacing-m;\r\n}\r\n\r\n@mixin padding-m-l {\r\n  padding: $spacing-m $spacing-l;\r\n}\r\n\r\n@mixin gap-h1 {\r\n  column-gap: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "styles_content__15oFw"
};
export default ___CSS_LOADER_EXPORT___;
