// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_diamond__1xRAE {\n  background-color: #e6e6e6;\n  transform: rotate(45deg);\n}", "",{"version":3,"sources":["webpack://src/components/Diamond/styles.module.scss","webpack://src/colors.module.scss"],"names":[],"mappings":"AAEA;EACE,yBCFkB;EDIlB,wBAAA;AAFF","sourcesContent":["@import \"../../theme.module.scss\";\r\n\r\n.diamond {\r\n  background-color: $color-neutral-100;\r\n\r\n  transform: rotate(45deg);\r\n}\r\n","$color-neutral-white: #ffffff;\r\n$color-neutral-100: #e6e6e6;\r\n$color-neutral-200: #d9d9d9;\r\n$color-neutral-300: #b3b3b3;\r\n$color-neutral-400: #999999;\r\n$color-neutral-500: #808080;\r\n$color-neutral-600: #595959;\r\n$color-neutral-700: #404040;\r\n$color-neutral-800: #292929;\r\n$color-neutral-black: #141414;\r\n\r\n$color-primary-light: #a486ff;\r\n$color-primary-medium: #7d58ec;\r\n$color-primary-dark: #572dd1;\r\n$color-primary-deep: #0a001a;\r\n\r\n$color-secondary-yellow-light: #e9e654;\r\n$color-secondary-yellow-medium: #e7e451;\r\n$color-secondary-yellow-dark: #c1be3a;\r\n\r\n$color-secondary-green-light: #01e279;\r\n$color-secondary-green-medium: #00cd6d;\r\n$color-secondary-green-dark: #00a759;\r\n\r\n$color-tertiary-blue: #399fe4;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"diamond": "styles_diamond__1xRAE"
};
export default ___CSS_LOADER_EXPORT___;
