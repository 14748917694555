import demonsRespiteThumbnail from "../../assets/demons-respite.PNG";

export const PROJECTS = [
  {
    title: "Demon's Respite",
    currentStatus: undefined,
    description: {
      text: "An 8-bit arcade style game made using JS canvas. This was my first official software development project which helped solidify a ton of basic coding concepts while working with new languages and tools. The entire app is powered by a 'SceneManager' class which works alongside a hierarchy of classes that each handle their own portion of gameplay. This project taught me core fundamentals of web development, the benefits of having class hierarchies, and the importance of file modularity.",
      keyWords: [
        "arcade",
        "game",
        "canvas",
        "solidify",
        "basic",
        "concepts",
        "taught",
        "fundamentals",
        "development",
        "benefits",
        "hierarchies",
        "importance",
        "modularity",
      ],
    },
    skillSet: ["JavaScript", "HTML", "CSS", "Git", "First Project"],
    thumbnail: demonsRespiteThumbnail,
    gitUrl: "https://github.com/Joseph-Galante/Demons-Respite",
    appUrl: "https://joseph-galante.github.io/Demons-Respite/",
  },
  {
    title: "Interventions Framework | Scene Health",
    currentStatus: undefined,
    description: {
      text: "Collaborating with two other developers, I contributed to a comprehensive project that developed extensive frameworks for a scalable system designed to facilitate patient interventions, thereby informing the actions of healthcare professionals. This project featured a highly modular frontend system, enabling diverse intervention types to be presented in a uniform manner, alongside numerous backend endpoints and an event system tailored to manage triggers associated with patient progression through an intervention. Furthermore, I played a key role in establishing the foundation for all necessary data models and typings to support the project's infrastructure.",
      keyWords: [
        "Collaborating",
        "developers",
        "comprehensive",
        "scalable",
        "featured",
        "modular",
        "diverse",
        "types",
        "presented",
        "uniform",
        "endpoints",
        "event",
        "system",
        "triggers",
        "progression",
        "establishing",
        "foundation",
        "models",
        "support",
        "infrastructure",
      ],
    },
    skillSet: [
      "React",
      "JavaScript",
      "HTML",
      "CSS",
      "Git",
      "Bitbucket",
      "Figma",
      "Apollo",
      "GraphQL",
      "Node",
      "PostgreSQL",
      "TypeScript",
    ],
    thumbnail: undefined,
    gitUrl: undefined,
    appUrl: undefined,
  },
  {
    title: "Company Rebrand | Scene Health",
    currentStatus: undefined,
    description: {
      text: "I spearheaded a comprehensive overhaul of the application's frontend design system, along with the fundamental components dependent on it. As the exclusive developer, I collaborated intimately with a designer, dedicating myself to executing their concept with pixel-perfect precision. Leveraging tools such as Figma and SASS, I successfully standardized and updated the most frequently utilized components, simultaneously eliminating any that were outdated.",
      keyWords: [
        "spearheaded",
        "overhaul",
        "frontend",
        "system",
        "exclusive",
        "developer",
        "collaborated",
        "designer",
        "executing",
        "pixel-perfect",
        "standardized",
        "eliminating",
      ],
    },
    skillSet: [
      "React",
      "JavaScript",
      "HTML",
      "CSS",
      "Git",
      "Bitbucket",
      "Figma",
      "Largest Project",
      "TypeScript",
    ],
    thumbnail: undefined,
    gitUrl: undefined,
    appUrl: undefined,
  },
  {
    title: "Unreal RPG",
    currentStatus: "In Progress",
    description: {
      text: "An RPG playground built in Unreal Engine 5 for developing and demoing core game functionality. Notable features include multiplayer trading via server-client RPCs, infinite terrain generation performed asynchronously at runtime using FRunnable worker threads, and various gameplay systems utilizing the Gameplay Ability System (GAS) framework.",
      keyWords: [
        "playground",
        "developing",
        "core",
        "functionality",
        "multiplayer",
        "RPCs",
        "infinite",
        "generation",
        "asynchronously",
        "runtime",
        "threads",
        "gameplay",
        "utilizing",
        "Gameplay",
        "Ability",
        "System",
      ],
    },
    skillSet: ["Unreal 5", "C++", "Git", "Blender"],
    thumbnail: undefined,
    gitUrl: undefined,
    appUrl: undefined,
  },
];
