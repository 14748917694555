// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_info__22xol {\n  color: #01e279;\n}\n\n.styles_link__35ZL8 {\n  width: auto;\n  max-width: -moz-fit-content;\n  max-width: fit-content;\n  color: #e7e451;\n}\n.styles_link__35ZL8:hover {\n  color: #399fe4;\n}", "",{"version":3,"sources":["webpack://src/pages/Contact/styles.module.scss","webpack://src/colors.module.scss"],"names":[],"mappings":"AAEA;EACE,cCiB4B;ADlB9B;;AAIA;EACE,WAAA;EACA,2BAAA;EAAA,sBAAA;EAEA,cCO8B;ADThC;AAIE;EACE,cCWkB;ADbtB","sourcesContent":["@import \"../../theme.module.scss\";\r\n\r\n.info {\r\n  color: $color-secondary-green-light;\r\n}\r\n\r\n.link {\r\n  width: auto;\r\n  max-width: fit-content;\r\n\r\n  color: $color-secondary-yellow-medium;\r\n\r\n  &:hover {\r\n    color: $color-tertiary-blue;\r\n  }\r\n}\r\n","$color-neutral-white: #ffffff;\r\n$color-neutral-100: #e6e6e6;\r\n$color-neutral-200: #d9d9d9;\r\n$color-neutral-300: #b3b3b3;\r\n$color-neutral-400: #999999;\r\n$color-neutral-500: #808080;\r\n$color-neutral-600: #595959;\r\n$color-neutral-700: #404040;\r\n$color-neutral-800: #292929;\r\n$color-neutral-black: #141414;\r\n\r\n$color-primary-light: #a486ff;\r\n$color-primary-medium: #7d58ec;\r\n$color-primary-dark: #572dd1;\r\n$color-primary-deep: #0a001a;\r\n\r\n$color-secondary-yellow-light: #e9e654;\r\n$color-secondary-yellow-medium: #e7e451;\r\n$color-secondary-yellow-dark: #c1be3a;\r\n\r\n$color-secondary-green-light: #01e279;\r\n$color-secondary-green-medium: #00cd6d;\r\n$color-secondary-green-dark: #00a759;\r\n\r\n$color-tertiary-blue: #399fe4;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "styles_info__22xol",
	"link": "styles_link__35ZL8"
};
export default ___CSS_LOADER_EXPORT___;
